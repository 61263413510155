@import "../../../styles/variable.module.scss";
.sellNFTSec {
  @include large_device {
    padding-left: 3px;
    padding-right: 3px;
  }
  @include medium_device {
    padding-bottom: 70px;
  }
  @include mobile_device {
    padding-bottom: 35px;
  }
}
.createNFTSlider {
  padding: 40px;
  border-radius: 16px;
  background-color: var(--light-bg);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @include mobile_device {
    padding: 20px;
  }
  .createNft {
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
    @include medium_device {
      height: 450px;
      margin-right: 35px;
    }
    .title {
      @include medium_device {
        font-size: 32px;
      }
      @include mobile_device {
        font-size: 22px;
      }
    }
    p {
      font-size: 14px;
      color: inherit;
      @include medium_device {
        line-height: 22px;
      }
    }
    .desc {
      font-size: 14px;
      line-height: 24px;
      color: rgb(20 20 22 / 70%);
      width: 100%;
      a {
        color: var(--variantColor);
        font-weight: 500;
      }
    }
  }
  ul {
    margin-top: 12px;
    padding-left: 20px;
    list-style: disc;
    margin-bottom: 0;
    li {
      font-size: 14px;
      line-height: 24px;
      color: rgb(20 20 22 / 70%);
      @include medium_device {
        line-height: 22px;
      }
    }
  }
  [class*="swiper"] {
    &[class*="-autoheight"] {
      @include mobile_device {
        padding: 20px 0;
      }
      [class*="slide"] {
        height: auto !important;
        opacity: 0;
        &[class*="active"] {
          opacity: 1;
        }
      }
    }
  }
  [class*="-bullets"] {
    position: absolute;
    top: 50%;
    right: 15px;
    list-style: none;
    @include mobile_device {
      right: 0;
    }
    [class*="-bullet"] {
      height: 15px;
      text-align: center;
      margin: 10px auto !important;
      background-color: var(--variantColor);
      &:first-child {
        width: 8px;
        height: 8px;
      }
      &:nth-child(2) {
        width: 7px;
        height: 7px;
      }
      &:nth-child(3) {
        width: 6px;
        height: 6px;
      }
      &:nth-child(4) {
        width: 5px;
        height: 5px;
      }
      &:nth-child(5) {
        width: 4px;
        height: 4px;
      }
      &:last-child {
        width: 3px;
        height: 3px;
      }
      &[class*="-active"] {
        opacity: 1;
        background-color: var(--variantColor);
      }
      button {
        font-size: 0;
        background: var(--variantColor);
        opacity: 0.4;
        border-radius: 50px;
      }
    }
  }
  [class*="-button-next"],
  [class*="-button-prev"] {
    background: var(--background-color);
    border: 2px solid var(--border-color);
    right: 40px;
    transform: rotate(90deg);
    line-height: 36px;
    left: auto;
    @include mobile_device {
      right: 3px;
    }
  }
  [class*="-button-prev"] {
    top: 28%;
    bottom: auto;
  }
  [class*="-button-next"] {
    top: auto;
    bottom: 28%;
  }
}
[data-theme="dark"] {
  .createNFTSlider{
    .createNft{
      .desc{
        color: $text-color;
      }
    }
    ul li{
      color: $text-color;
    }
  }
}
[dir="rtl"] {
  .createNFTSlider {
    .createNft {
      margin-left: 35px;
      margin-right: 0;
    }
    .desc {
      ul {
        padding-left: 0;
        padding-right: 20px;
      }
    }
    [class*=-bullets] {
      left: 15px;
      right: auto;
      @include mobile_device {
        left: 0;
      }
    }
    [class*="-button-next"],
    [class*="-button-prev"] {
      left: 40px;
      right: auto;
      @include mobile_device {
        left: 3px;
      }
    }
  }
}
